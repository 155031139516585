
.org_head{
    display: flex;
   justify-content: space-between;
   align-items: center;
   @media screen and (max-width: 480px) {
    align-items: unset;
    flex-direction: column;
   }
}
.top_header{
    display: flex;
    align-items: center;
    @media screen and (max-width: 480px) {
        margin-bottom: 12px;
    }
}
.bar_nav {
    background-color: white;
    padding: 10px 0;
    margin-bottom: 10px;
    margin-top: -25px;
    border-bottom: 2px solid rgb(246, 247, 251);
    z-index: 1;
   
  .org_btn {
    color: var(--tal_primary) ;
    min-width: 50px;
    height: 50px;
    border: 2px solid var(--tal_primary) ;
    border-radius: 100%;
    margin-right: 10px;
  }
}
  

 
        
   