.image_title{
    font-weight: 400;
    margin: 0;
    color: var(--tal_primary);
    text-align: left;
    padding: 0 10px;
}
.add_image_container{

padding: 0;
width: 100%;
}