@import "../../sass/variables";
@import "../../sass/mixins";
.title_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heart_btn {
  display: flex;
  margin-top: -15px;
  label {
    display: unset;
    margin-bottom: unset;
  }
}
.heart_b {
  background-image: url(/images/web_heart_animation.png);
  background-position: left;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  cursor: pointer;
  //   position: absolute;
  //   left: -14px;
  background-size: 2900%;
}
.heart_b:hover,
.heart_b:focus {
  background-position: left;
}
.heart_user {
  background-image: url(/images/heart.png);
  background-position: center;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  cursor: pointer;
  //   position: absolute;
  //   left: -14px;
  background-size: 50%;
}

@-webkit-keyframes heart_Blast {
  0% {
    background-position: left;
  }

  100% {
    background-position: right;
  }
}

@keyframes heart_Blast {
  0% {
    background-position: left;
  }

  100% {
    background-position: right;
  }
}

.heart_Animation {
  display: inline-block;
  -webkit-animation-name: heart_Blast;
  animation-name: heart_Blast;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: steps(28);
  animation-timing-function: steps(28);
  background-position: right;
}
