.custom_error {
  font: 500 12px "Roboto" !important;
  color: red;
}
.bg_normal {
  border: 1px solid #ccc !important;
  outline: unset;
}
.bg_green {
  border: 1px solid #ccc !important;
  outline: unset;
}
.bg_red {
  border: 1px solid #fd2a2a !important;
  outline: unset;
}
.plasma_input {
  border-radius: 4px;
  fieldset {
    border-color: unset;
    border-style: unset;
    border-width: unset;
  }
  button {
    margin-top: -12px;
  }
  label {
    background: #fff;
  }
}
.blood_grp {
  width: 100%;
  label {
    top: 8px;
    background: #fff;
  }
  fieldset {
    border-color: unset;
    border-style: unset;
    border-width: unset;
  }
}
.date_b {
  label {
    // padding-left: 10px;
    // position: absolute;
    // top: -9px;
    background: #fff;
    // left: 4px;
  }
  div {
    padding-left: 15px;
  }
  div:before {
    border-bottom: none !important;
  }
}
