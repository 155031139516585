.playlists{
    display: flex;
    gap: 10px;
    img{
        width: 100%;
    border: 5px solid #f1f1f1;
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}



.manage_playlist_livestreamings{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .manage_playlist{
        min-width: 50%;
        @media screen and (max-width: 600px) {
            min-width: 100%;
        }
    }
}