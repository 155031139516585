@import "../../sass/variables";
@import "../../sass/mixins";

.TalRadioPlayer {
  margin: 0 auto;
  padding: 0 10%;
  display: flex;
  position: relative;
  top: 0;
  // background: #f1f1f1;

  .podcast-child-cont div {
    width: 100% !important;
  }

  .action-btn {
    align-self: self-start;
    // background: rgb(202, 202, 202);
    border-radius: 0px;
  }

  figure {
    max-width: 500px;
    max-height: 500px;
    margin: 0 auto;
  }

  .podcast-cont .podcast-child-cont {
    text-align: center;
    background: white;
    img {
      max-height: 350px;
      min-height: 250px;
    }
  }

  .podcast-cont p,
  h2 {
    white-space: normal;
    text-overflow: unset;
    overflow: visible;
  }

  .more-videos {
    background-color: #e7e7e7;
    height: 434px;

    h4 {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #283e4a;
      background: #dddddd;
      padding: 10px;
    }
  }
  .podcast-cont {
    //  width: 50%;
    //   margin: 0 auto;
    //   height: auto !important;

    //   @media screen and (max-width: 960px) {
    //     width: 100%;
    //   }

    button:nth-child(n) {
      padding: 0;
    }
  }

  @media screen and (max-width: 960px) {
    padding: 0 5% !important;
  }
}
