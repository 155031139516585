@import "../../sass/variables";
@import "../../sass/mixins";

.favorites {
  .postCard {
    margin-bottom: 15px;
  }

  .container-center .postCardPaper {
    margin: 0 !important;
    text-align: left;
    h5 {
      padding: 0 15px;
    }
  }
  .container-center img {
    max-width: 100%;
    min-width: 100%;
  }

  .home-footer {
    margin: 10px 0;
    padding: 15px;
    background: #fff;
    border-top: 1px solid #000;
  }
  .max-img {
    max-width: 100%;
    min-width: 100%;
  }
  .myreq-sublist {
    display: inline-block;
    width: 12%;
    margin: 0 15px;
    padding: 0;
    span {
      font: 500 20px $font-regular;
      color: #aaa;
    }
    &:first-child {
      width: 07%;
    }
  }
  .no-req-text {
    text-align: center;
    padding: 20px 0;
    // margin: 10px 0;
    font: 600 20px $font-regular;
    font-style: italic;
  }
}

@media only screen and (max-width: 600px) {
  .container-center {
    max-width: 100% !important;
    flex-basis: 100% !important;
    margin-top: 0 !important;
    text-align: center;
  }
  // .favorites {
  //   padding: 50px !important;
  // }
  .side-cont {
    display: none;
  }
}
