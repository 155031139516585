@import "../../../sass/variables";
@import "../../../sass/mixins";

.password-reset-container {
 
  .headername {
    font-family: $font-regular;
    font-size: 26px;
    text-align: center;
  }
  .resetpassword-input {
    @include input-fields;
    padding: 20px 0 10px;
  }
}

.forgot-reset-password {
  width: 100%;
  margin: 0 auto;
}

.otp-container {
  min-width: 280px;
  margin: 0 auto;
  // padding: 36px;
  // height: 518px;
}
.otp-boxes {
  div {
    justify-content: center;
    margin: 10px 0;
    div {
      input {
        width: 40px !important;
        height: 40px;
        border-radius: 5px;
        border:1px solid #8692A6;
      }
    }
  }
}

.timerOTP {
  display: flex;
  justify-content: space-between;
  max-width: 260px;
  margin: 0 auto;
}



@media screen and (max-width: 600px) {
 
  .otp-container {
    width: 100%;
    padding: 30px 10px;
  }
}
