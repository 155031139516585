.org-details-container {
  width: 85%;
  margin: 0 auto;
  .org-details {
    .org-header {
      padding: 10px;
      background: #fff;
      border: 1px solid #e0e0e0;
      box-shadow: 0 8px 6px -6px #bfbfbf;
    }
    .org-name {
      color: #3387a7;
      text-align: center;
      img {
        margin-right: 12px;
      }
    }
    .org-type {
      display: inline-block;
      text-align: end;
      width: 41%;
      color: #aaa;
    }
    .org-loc {
      display: inline-block;
      text-align: end;
      width: 29%;
      color: #aaa;
    }
  }
}
