@import "../../sass/variables";
@import "../../sass/mixins";

.manage_podcast_media {

  .hamburger-icon {
    position: fixed;
    z-index: 10;
    background-color: white !important;
    border: 2px solid #eee;
    padding: 12px 14px;
    margin: 30px 0 0 12px;
    left: 0;
  }

  .image-title {
    // font-size: 24px;
    font-weight: 400;
    margin: 0;
    line-height: 50px;
    color: var(--tal_primary) ;
    text-align: left;
    padding:0 10px;
  }

  .add-image-container h4 {
    font: 300 20px "Roboto";
    text-align: center;
  }
  .podcast-table th {
    font: 600 14px "Roboto";
    width: 280px;
  }

  .dropzone-container {
    cursor: pointer;
    text-align: center;
    margin: 10px auto;
    padding: 20px;
    // border: 2px dashed #eee;
    min-height: 177px;
    display: grid;
    place-content: center;
  }

  .MuiFilledInput-input {
    padding: 10px;
  }

  .add-image-container {
    padding: 0;
    width: 100%;
    .del-btn {
      margin-right: 30px;
      background: #f1ebeb;
      padding: 5px;
    }
    .MuiIconButton-root:hover {
      background-color: #00000038 !important;
    }
  }

  .add-image-container .child-container {
    width: 100%;
    margin: 0 !important;
  }

  .select {
    text-align: center;
    h5 {
      font-size: 13px;
      margin: 0;
    }
    @media screen and (max-width: 600px) {
      margin: 20px 0;
    }
  }

  table {
    position: sticky;

    button {
     

      @media screen and (max-width: 600px) {
        padding-right: 0 !important;
      }
    }
  }
  .main-1,
  .main-2,
  .main-3 {
    padding: 15px;
    position: relative;
    border-radius: 4px;
    min-height: 100%;
    margin-bottom: 10px;

    .MuiIconButton-root:hover {
      background-color: transparent !important;
    }
  }
  .main-1 h4 {
    margin-bottom: 27px;
   
  }
  .dropzone {
    position: relative;
    left: 0;
    right: 0;
    top: 30%;
  }
}
