.template-thumbnail {
  display: flex;
  flex-wrap: wrap;
  background: white;
}

.email {
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;
  box-shadow: 0 0 9px #e8eaf5;
  white-space: nowrap;
  font-size: 18px;
}

.start-email {
  ul {
    margin: 30px 30px 20px 0;
  }
}

.emailcampaign {
  .email-template {
    padding: 5px 10px;
  }
  .content-cell {
    padding: 10px !important;
  }
  b {
    width: 100%;
  }
  label {
    font-size: 14px;
  }
  label + .MuiInput-formControl {
    margin-top: 25px;
  }
  h4 {
    color: var(--tal_primary);
    text-align: center;
    font-weight: 400;
  }
  .template {
    width: 100%;
    display: flex;
    flex-direction: row;

    div {
      margin: 4px 0;
    }
    button {
      border: solid 1px var(--tal_primary);
      color: var(--tal_primary);
      white-space: nowrap;
      padding: 2px 5px;
      font-size: 12px;
    }
    .MuiButton-root:hover {
      background: var(--tal_primary);
      color: white;
    }
  }
  .MuiButton-contained {
    background-color: var(--tal_primary) ;
    color: white;
    box-shadow: none;
  }
  .MuiButton-contained:hover {
    background-color: var(--tal_primary) ;
    color: white;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  .form-btn {
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
    @media screen and (max-width: 400px) {
      flex-direction: column;
    }
  }
  .close-image-popup button {
    margin-top: 10px;
  }
}
