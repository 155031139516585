.email-body {
  background: rgb(246, 247, 251);
  position: sticky;
  top: 0;
  z-index: 1;
  .box-text {
    width: 100%;
  }
  .email-groups {
    border-bottom: rgb(246, 247, 251) 1px solid;
    padding: 10px 10px 0 10px;

    h4 {
      color: rgb(205, 38, 39);
      text-align: center;
    }
    .button-groups {
      display: flex;
    }
  }
  .org-container {
    background: white;
  }
}
