.barnav {
  background-color: white;
  padding: 10px 0;
  margin-bottom: 20px;
  margin-top: -25px;
  position: sticky;
  top: 85px;
  border-bottom: 2px solid rgb(246, 247, 251);
  z-index: 6;

  .DayPickerKeyboardShortcuts_show {
    border: none !important;
  }
  .DateRangePickerInput__withBorder {
    border-radius: 4px !important;
    border: 1px solid #cbcbcb !important;
  }
  .DateRangePickerInput__withBorder button {
    margin: 0 !important;
    min-width: 30px !important;
  }

  .DateRangePickerInput__withBorder .DateInput {
    width: 100px;
  }

  .DateRangePickerInput__withBorder .DateInput_input {
    font-size: 14px;
    font-weight: 400;
  }
  .appbar-icon {
    margin-top: 10px;
  }
  .DateRangePickerInput_calendarIcon {
    border: none !important;
    margin: 0;
  }
  .MuiInputLabel-shrink {
    transform: translate(12px, -4px) scale(0.75) !important;
  }
  @media screen and (max-width: 960px) {
    position: relative;
    top: 2px;
    width: 100%;
    // z-index: 10;
  }

  h4 {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: var(--tal_primary);
    margin: 0;
  }

  .search-right {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
  }
}

.barnav .managebtn {
  color: var(--tal_primary);
  min-width: 50px;
  height: 50px;
  border: 2px solid var(--tal_primary);
  border-radius: 100%;
  margin-right: 10px;
}

.bar-main {
  display: flex;
  margin-bottom: 5px;
  padding: 6px;
  justify-content: space-between;
  position: sticky;
  top: 165px;
  // width: 100%;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  .result {
    margin: 0;
    margin-top: 8px;
    @media screen and (max-width: 600px) {
      padding: 10px 0;
      margin: 0;
    }
    @import "../../sass/variables";
    @import "../../sass/mixins";

    .plasma {
      // width: 60%;
      padding: 25px;
      display: inline-block;
      @media (max-width: 600px) {
        width: 100%;
        padding: 20px;
      }
    }

    .close-organizatin {
      margin: 0;
      :hover {
        color: rgb(205, 38, 39);
      }
    }
    .org-container {
      padding-left: 20px;
      .org-title {
        color: #058ad0;
        font: 600 20px "Roboto";
        text-transform: uppercase;
      }
    }
  }
}
.admin_head {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &:last-child {
    gap: 20px;
    flex-wrap: wrap;
    @media screen and (max-width: 425px) {
      > div {
        flex: 100%;
      }
    }
  }

}
.searchadminall {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  padding-bottom: 8px;
  .adminselect {
    flex: 15%;
    @media screen and (max-width: 768px) {
      flex: 25%;
    }
    @media screen and (max-width: 425px) {
      flex: 100%;
    }
  }
  .mentorselect {
    flex: 14%;
    @media screen and (max-width: 768px) {
      flex: 25%;
    }
    @media screen and (max-width: 480px) {
      flex: 100%;
    }
    label {
      margin-top: -12px;
    }
  }

  .searchbar {
    position: relative;
    @media screen and (max-width: 480px) {
      flex: 100%;
    }
    .result {
      position: absolute;
      display: flex;
      top: 65px;
      font-size: 12px;
    }
  }
  .search_admin {
    background: #eee;
    padding: 12px;
    border-radius: 5px;
    svg {
      float: right;
    }
  }
  .admin_select {
    flex: 11%;
    @media screen and (max-width: 1024px) {
      flex: 18%;
    }
    @media screen and (max-width: 768px) {
      flex: 20%;
    }
    @media screen and (max-width: 480px) {
      flex: 100%;
    }
    label {
      margin-top: -12px;
    }
  }
}
.admincontainer {
  tr {
    th {
      border-bottom: unset;
      border: unset;
    }
  }
  .adminsrn {
    display: flex;
    align-items: center;
    a {
      color: black;
    }
    > label {
      margin: 0;
    }
    svg {
      cursor: pointer;
    }
  }
  label {
    margin-top: -12px;
  }
}
.podcast-detail-conts {
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
}
.clearbtn {
  color: var(--tal_primary) !important;
  min-width: 50px !important;
  height: 50px !important;
  border: 2px solid var(--tal_primary) !important;
  border-radius: 5px !important;
  margin-right: 0px !important;
  height: 55px !important;
}
.managetable {
  th {
    color: #000 !important;
    background-color: #fddad8 !important;
    padding: 24px 16px;
  }
  table {
    table-layout: fixed;
  }

  span {
    font: 500 14px "Roboto";
    display: block;
    width: max-content;
  }
  svg {
    color: #000;
  }
  span {
    &:focus {
      color: #000 !important;
    }
    &:hover {
      color: #000 !important;
    }
  }
}
.managetopbar {
  position: static !important;
  background-color: unset !important;
  box-shadow: unset !important;
  > div {
    box-shadow: unset !important;
    // > div {
    //   @media screen and (max-width: 768px) {
    //     overflow-x: auto !important;
    //   }
    // }
  }
}
.manage_display {
  overflow-x: auto;
}

.manage_btn {
  background-color: rgba(0, 0, 0, 0.04) !important;
  color: #000;
}
.manage_btn_active {
  background-color: var(--tal_primary) !important;
  color: #fff !important;
}
.manage_topbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
  }
}
.manage_table {
  display: inline-grid;
}
.manage_app {
  display: flex;
  gap: 10px;
  margin-bottom: 12px;
}
