.signup_c {
  margin: 24px 0 24px 0;
  a {
    color: #f44336 !important;
    font-weight: bold;
     &:hover {
       color: #f44336;
       
     }
  }
}
.power_tag {
  margin-top: 24px;
  text-align: center;
  @media screen and (min-width: 960px) {
    display: none;
  }
}
