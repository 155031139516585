.edit-container {
  .searchcontact {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 5px;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  h4 {
    color: #ef6c00;
    line-height: 30px;
    align-self: center;
  }
  .email-header {
    border-bottom: rgb(246, 247, 251) 1px solid;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
 
  .email-body {
    padding: 15px;

    .user-name-form {
      font-size: 14px;
      text-align: center;
      line-height: 30px;

      :nth-child(1) {
        font-weight: bold;
        font-size: 18px;
      }
      :nth-child(2) {
        margin-bottom: 20px;
      }
    }
  }
  .save-button {
    text-align: right;
  }
  .box-grid {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    margin: 20px 0;
    header {
      display: flex;
      justify-content: space-between;
      background: #f3f3f3;
      border-bottom: 1px solid #d9d9d9;
      padding: 10px;
      span {
        margin: 0;
      }
    }
    section {
      padding: 10px;
      .email-design {
        display: flex;
        gap: 20px;
        @media screen and (max-width: 600px) {
          display: block;
        }
      }
      div {
        line-height: 3rem;
        font-weight: 400;
        :nth-child(1) {
          padding-right: 5px;
        }
        span {
          font-weight: 600;
        }
        div {
          font-size: 14px;
        }
      }
    }
  }
}
