.ck-editor-sc .ck-rounded-corners .ck-editor__main .ck-content {
    height: 200px !important;
  }
  .ship_amt{
    display: flex;
  }
  .MuiInput-underline:before, .MuiInput-underline:after{
    display: none;
  }
  .focus-disabled .MuiSelect-select:focus{
    background-color: unset !important;
  }
  .request-two-step{
    margin-top: 10px;
  }
   .field-length{
    width: 90% !important;
    margin: auto !important;
   }