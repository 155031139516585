.tal-container {
  padding-left: 215px !important;
  padding-right: 50px !important;
  margin-top: 80px;
  @media screen and (max-width: 768px) {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  @media screen and (max-width: 480px) {
    margin-top: 115px;
  }
}
.width100 {
  width: 100%;
}
.clrblack {
  color: #000;
}
#header {
  background: rgb(0, 184, 238);
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
}

#header.header-scrolled {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

#header.header-inner-pages {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

#header .logo a {
  color: #556270;
}

#header .logo img {
  max-height: 40px;
  width: 100%;
}

.profile-field {
  div {
    padding: 14px;
    border-bottom: none;
  }

  &.dob {
    margin: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-bottom: none;
  }
}
/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  margin: 0 5px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #556270;
  white-space: nowrap;
  transition: 0.3s;
  border-radius: 4px;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
  color: #fff;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #d9232d;
}

.navbar .getstarted {
  background: #fff;
  padding: 8px 25px;
  margin-left: 30px;
  border-radius: 0;
  font-weight: 400;
  color: #d9232d;
  border: 2px solid #d9232d;
}

.navbar .getstarted:hover {
  color: #d9232d;
  background: #fff;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  margin: 0;
  padding: 10px 0;
  border-radius: 3px;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  border-bottom: 2px solid #ccc;
}

.navbar .dropdown ul li:last-child {
  border-bottom: none;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #d9232d;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #ffffff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
    margin-left: 12px;
  }

  /* .navbar ul {
          display: none;
      } */
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(63, 73, 83, 0.9);
  transition: 0.3s;
  z-index: 999;
}
@media (max-width: 991px) {
  .navbar-mobile {
    display: none;
  }
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #556270;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #d9232d;
}

.navbar-mobile .getstarted {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #d9232d;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 60vh;
  background-color: rgba(63, 73, 83, 0.8);
  overflow: hidden;
  position: relative;
}

#hero .carousel,
#hero .carousel-inner,
#hero .carousel-item,
#hero .carousel-item::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#hero .carousel-item::before {
  content: "";
  /* background-color: rgba(30, 35, 40, 0.6); */
}

#hero .carousel-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 1em;
  right: 8em;
}

#hero .container {
  text-align: center;
}

#hero h2 {
  color: #fff;
  margin: 20px 0;
  font-size: 48px;
  font-weight: 700;
}

#hero p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 5px;
  color: #fff;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 30px;
  line-height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.5);
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hero .carousel-control-next-icon:hover,
#hero .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

#hero .carousel-indicators li {
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: 0.6;
  transition: 0.3s;
}

#hero .carousel-indicators li.active {
  opacity: 1;
  background: #d9232d;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: #d9232d;
}

#hero .btn-get-started:hover {
  background: #df3740;
}

.bg-header {
  background-color: #1967e5;
  > div {
    display: flex;
    align-items: center;
    text-align: center;
  }
  h1 {
    font-size: 40px;
    color: #fff;
    @media screen and (max-width: 600px) {
      font-size: 25px;
    }
  }
  p {
    font-size: 20px;
    color: #fff;
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
  img {
    width: 50%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}

#hero .banner-bg {
  /* background: #00b8ee url(../img/hero-banner.jpg) no-repeat;*/
  /* background-size: 30%; */
  background-position: left 15% center !important;
}

@media (max-width: 992px) {
  #hero {
    height: 100vh;
  }

  #hero .carousel-container {
    top: 8px;
  }
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 28px;
  }
}

@media (min-width: 1024px) {
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
/* section {
    padding: 60px 0 0;
    overflow: hidden;
  } */

.section-bg,
.services .icon-box {
  background-color: #f8f9fa;
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Roboto", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 90%;
  height: 1px;
  display: inline-block;
  background: white;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  color: #556270;
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
  padding: 18px 0;
  /* background: #f3f3f3; */
  min-height: 40px;
  margin-top: 60px;
  border-bottom: 1px solid #e2e2e2;
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  color: #000;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6b7b8d;
  content: "/";
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 58px;
  }

  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs h2 {
    margin-bottom: 10px;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
.about .content h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.about .content h3 {
  font-weight: 500;
  line-height: 32px;
  font-size: 24px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  /* padding: 10px 0 0 28px; */
  position: relative;
}

.about .content ul i {
  left: 0;
  top: 7px;
  position: absolute;
  font-size: 20px;
  color: #05a0ce;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
.clients {
  padding: 15px 0;
  text-align: center;
}

.clients img {
  max-width: 100%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 20px 0;
}

.clients img:hover {
  filter: none;
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 6px;
}

.services .icon-box i {
  float: left;
  color: #d9232d;
  font-size: 40px;
}

.services .icon-box h4 {
  margin-left: 70px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .icon-box h4 a {
  color: #556270;
  transition: 0.3s;
}

.services .icon-box p {
  margin-left: 70px;
  line-height: 24px;
  font-size: 14px;
}

.services .icon-box:hover h4 a {
  color: #d9232d;
}

/*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #d9232d;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(85, 98, 112, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(85, 98, 112, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
  min-width: 100%;
  margin: auto;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 5px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: rgba(255, 255, 255, 0.6);
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: white;
}

.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #d9232d;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #d9232d;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0 0 30px rgba(85, 98, 112, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
  # Our Team
  --------------------------------------------------------------*/
.team .member {
  position: relative;
  box-shadow: 0 2px 15px rgba(85, 98, 112, 0.08);
  padding: 30px;
  border-radius: 20px;
  background: white;
}

.team .member .pic {
  overflow: hidden;

  border-radius: 4px;
}

.team .member .pic img {
  transition: ease-in-out 0.3s;
}

/*
  .team .member:hover img {
    transform: scale(1.1);
  }*/

.team .member .member-info {
  margin: 0 auto;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #556270;
}

.team .member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.team .member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #dee2e6;
  bottom: 0;
  left: 0;
}

.team .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.team .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.team .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  background: #8795a4;
}

.team .member .social a i {
  color: #fff;
  font-size: 16px;
  margin: 0 2px;
}

.team .member .social a:hover {
  background: #d9232d;
}

.team .member .social a + a {
  margin-left: 8px;
}

/*--------------------------------------------------------------
  # Our Skills
  --------------------------------------------------------------*/
.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  padding: 10px 0;
  margin: 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #556270;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #edeff1;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #6b7b8d;
}

/*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
.features {
  overflow: hidden;
}

.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  border: 0;
  padding: 12px 15px 12px 0;
  transition: 0.3s;
  color: #556270;
  border-radius: 0;
  border-right: 2px solid white;
  font-weight: 600;
  font-size: 15px;
}

.features .nav-link:hover {
  color: #d9232d;
}

.features .nav-link.active {
  color: #d9232d;
  border-color: #d9232d;
}

.features .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.features .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #556270;
}

.features .details p {
  color: #777777;
}

.features .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .features .nav-link {
    border: 0;
    padding: 15px;
  }

  .features .nav-link.active {
    color: #fff;
    background: #d9232d;
  }
}

/*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.pricing h3 {
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #777777;
  background: #f8f8f8;
}

.pricing h4 {
  font-size: 36px;
  color: #d9232d;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
}

.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #444444;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing ul li {
  padding-bottom: 16px;
}

.pricing ul i {
  color: #d9232d;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  text-align: center;
}

.pricing .btn-buy {
  background: #d9232d;
  display: inline-block;
  padding: 8px 35px 9px 35px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  transition: 0.3s;
}

.pricing .btn-buy:hover {
  background: #e1444d;
}

.pricing .featured h3 {
  color: #fff;
  background: #d9232d;
}

.pricing .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #d9232d;
  color: #fff;
}

/*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
.faq .faq-item {
  margin: 20px 0;
  padding: 20px 0;
  border-bottom: 1px solid white;
}

.faq .faq-item i {
  color: #dee2e6;
  font-size: 20px;
  float: left;
  line-height: 0;
  padding: 13px 0 0 0;
  margin: 0;
}

.faq .faq-item h4 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin: 0 0 10px 28px;
  font-family: "Roboto", sans-serif;
}

.faq .faq-item p {
  font-size: 15px;
}

/*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 40px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #f8d1d3;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #556270;
  float: left;
  width: 44px;
  height: 44px;
  background: #edeff1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #556270;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #8795a4;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #556270;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #d9232d;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #d9232d;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #e24d55;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/
.blog {
  padding: 40px 0 20px 0;
}

.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 440px;
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog .entry .entry-title {
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #556270;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #d9232d;
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #c1c8d0;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li + li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}

.blog .entry .entry-meta a {
  color: #777777;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  line-height: 24px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #d9232d;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #df3740;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #556270;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #a4afba;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #606f7e;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #d9232d;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li + li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .blog-author {
  padding: 20px;
  /* margin-bottom: 30px; */
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 50px;
  height: 50px;
  margin: 1em 20px 1em 0;
}

.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0;
  padding: 0;
  color: #556270;
}

.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .blog-author .social-links a {
  color: rgba(85, 98, 112, 0.5);
  margin-right: 5px;
}

.blog .blog-author p {
  font-style: italic;
  color: #b7b7b7;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog .blog-comments .comment .comment-img img {
  width: 60px;
}

.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444444;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #d9232d;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #556270;
}

.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #6b7b8d;
  margin-bottom: 5px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #e9797f;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #e9797f;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #556270;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #606f7e;
}

.blog .blog-pagination {
  color: #8795a4;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li a {
  color: #556270;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: #d9232d;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #556270;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
}

.blog .sidebar .search-form form input[type="text"] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #d9232d;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: #de323c;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #556270;
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: #d9232d;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaaaaa;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: #556270;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #d9232d;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #96a2af;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid white;
  display: inline-block;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #d9232d;
  background: #d9232d;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #fbfbfc;
  font-size: 14px;
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer {
  background: #4a5562;
  padding: 0 0 15px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-top {
  background: #515d6a;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #d9232d;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: white;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px 2px 20px;
  background: #d9232d;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #df3740;
}

#footer .copyright {
  text-align: center;
  padding-top: 15px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
}

#footer .credits a {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  font-weight: 600;
}

#footer .credits a:hover {
  color: white;
}

.sol-banner-desc {
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: left;
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 10px;
}

.sol-title-sub-lv3 {
  width: auto;
}

.sol-title-sub-lv3 h2 {
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: left;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.sol-title-head h1 {
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: left;
  font-size: 40px;
  font-weight: 700;
}

.sol-banner-pow {
  display: table-cell;
  width: 100%;
}

.sol-banner-pow > p {
  font-family: "Roboto", sans-serif;
  color: #ffff;
  margin: 0 0 0 0;
  font-size: 15px;
}

.sol-banner-btns a {
  display: block;
  float: left;
  background: #cc2727;
  color: #fff;
  border: 2px solid #cc2727;
  border-radius: 0;
  margin: 10px 0 0 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 30px;
}

.sol-banner-btns a:hover {
  background: transparent;
  border-color: #fff;
}

img.about-transformers-img {
  border-radius: 10px;
}

.about-transformers-text h2 {
  text-transform: none !important;
  color: #212121;
  font-family: "Roboto", sans-serif;
  font-size: 44px !important;
  font-weight: 700 !important;
  line-height: 1.2em !important;
  margin-bottom: 10px !important;
}

.about-transformers-text p {
  margin-bottom: 0;
}

.about-transformers-text ul {
  margin-bottom: 10px;
}

.about-transformers-text ul li {
  line-height: 18px;
  font-size: 15px;
}

.about-transformers-text ul li a {
  color: #05a0ce;
}

.about-transformers-text ul li a:hover {
  color: #d9232d;
}

.about-transformers-text p.fst-text-edit {
  font-weight: 700;
  text-align: left;
  color: #05a0ce;
  font-size: 23px;
}

.sdg-text h3 {
  color: #212121;
  font-family: "Roboto", sans-serif;
  font-size: 44px !important;
  font-weight: 700 !important;
  line-height: 1.2em !important;
}

.sdg-text p a {
  color: #000;
}

.sdg-text p a:hover {
  color: #d9232d;
}

.sdg-text ol > li {
  margin-left: 2.5em;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 1.4em;
  color: #000;
}

.sdg-text p.ml-5 {
  margin-left: 50px;
}

.sdg-text ul > li {
  margin-left: 4.5em;
  font-size: 14px;
  line-height: 1.4em;
  color: #000;
  padding: 0 !important;
  list-style: disc;
}

.mt-10 {
  margin-top: 6rem;
}

p.fst-text-edit-sdg {
  font-weight: 700;
  text-align: center;
  color: #05a0ce;
  font-size: 23px;
  margin: 0 30px 0 30px;
}

#team.section-bg {
  background-color: #6bc9e6;
}

.member p a.scoutsClubRegister {
  color: #05a0ce;
}

.member p a.scoutsClubRegister:hover {
  color: #d9232d;
}

#clients .global-participants-card {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.fw-700 {
  font-weight: 700;
  color: #000;
}

a.rytn {
  text-align: center;
  background: #cc2727;
  color: #fff;
  border: 2px solid #cc2727;
  border-radius: 3px;
  margin: auto;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 30px;
  display: inline-block;
}

a.rytn:hover {
  background: #fff;
  color: #cc2727;
  border: 2px solid #cc2727;
}

p {
  color: #000;
}

/*login page*/
.login .featured h3 {
  background: #05a0ce;
}

.login h4 {
  color: #000;
  margin-bottom: 0;
  text-align: left;
  font-size: 25px;
}

.login p,
.login label {
  text-align: left;
  color: #444444;
  font-size: 13px;
}

.login .btn-buy {
  display: block;
}

.login p.p-border {
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.login input[type="text"],
.login input[type="password"] {
  height: 50px;
  margin-top: 10px;
  border: 1px solid rgb(173, 171, 171);
}

.login input[type="text"]::placeholder,
.login input[type="password"]::placeholder {
  font-size: 13px;
  color: rgb(153, 150, 150);
}

.login .img-fluid {
  max-width: 70%;
}

.login .btn-wrap {
  margin-top: 0;
}

/*login page*/

/* login-enter-password*/
.enter-password .btn-buy {
  display: inline-block;
  border-radius: 0;
  padding: 15px 50px;
}

.enter-password .inactive {
  background: #ec9b9b;
  color: #cc2727;
}
.tsform-check {
  height: 100%;
}
.submit-idea .tsform-check .form-check-input {
  margin-left: 0;
  height: 15px;
  width: 15px;
  margin-top: 8px;
  position: absolute;
}

.submit-idea .tsform-check label {
  padding-left: 2em;
}

.tsform-check a,
.tsform-check label,
.tsform-check p {
  color: rgb(153, 150, 150);
  font-size: 12px;
  margin-right: 10px;
  font-weight: 500;
}

.tsform-check p {
  margin-top: 5px;
}

.tsform-check a {
  text-decoration: underline;
  color: #cc2727;
  font-weight: 500;
  margin-top: 5px;
}

label.error {
  color: #dc3545 !important;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
}

.form-group {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.form-group label {
  margin: 5px 0;
}

.form-group .form-control {
  height: 40px;
  border-radius: 5px;
  border: 1px solid #b7b7b7;
}

.form-group textarea.form-control {
  height: 200px;
}

.form-footer-bar {
  padding: 35px 10px;
  background: #f5f5f5;
  margin-top: 3em;
  border-top: 1px solid #e2e2e2;
}

.form-footer-bar .btn {
  border-radius: 3px !important;
}
/*  
  .btn-outline-danger.reset {
     background-color: #FFC8C8;
     border-color: #FFC8C8; 
  }*/

.bg-outline-danger {
  color: #cc2727 !important;
  background-color: #fff !important;
  border: 1px solid;
  border-color: #cc2727 !important;
  border-radius: 3px !important;
}

.btn-outline-danger.reset:hover {
  color: #cc2727;
  background-color: #fff;
  border-color: #cc2727;
}

.accordion-button:not(.collapsed) {
  color: #cc2727;
  background-color: #ffe8e8;
}

.email-generate-password p.p-border {
  border-bottom: none;
}

.bg-color {
  background: #05a0ce !important;
}

.avatar-tr {
  vertical-align: middle;
  width: 130px !important;
  height: 130px !important;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0 1px 2px #000;
}

li.dropdown a.img-dropdown,
li.dropdown a.img-dropdown:focus {
  padding: 0 !important;
}

li.dropdown span.avatar-span {
  color: #fff;
  padding: 0 5px 0 5px;
}

.nav-tabs {
  background: rgb(238, 234, 234);
}

.nav-tabs .nav-item .nav-link.active {
  color: white;
  background-color: #d9232d;
  border-color: #d9232d;
  border-radius: 3px;
}

.nav-link:focus,
.nav-link:hover {
  color: #000;
}

.nav-link {
  color: #000;
  font-weight: 600;
}

.tab-content .team .member .pic {
  overflow: hidden;
  border-radius: 2px;
  background: bisque;
}

.member-info button.font-weight-bold {
  font-weight: 600;
  background: #00b8ee;
}

.tab-content .team strong {
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;
}

.tab-content .team p {
  font-size: 12px;
}

.tab-content .team .bg-card {
  background: #f3f3f3;
}

.tab-content .member-info iframe {
  width: 220px;
  height: auto;
}

.border-1 {
  border: 1px solid #ddd;
  border-radius: 6px !important;
}

.margin-left-1 {
  margin-left: 1rem !important;
}

.status .member-info h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #000;
  margin-top: 5px;
}

.status .member-info h6 {
  font-size: 16px;
  font-weight: 400;
  color: #4a5568;
  line-height: 20px;
  margin: 0;
}

.status .member-info p {
  font-size: 16px;
  font-weight: 400;
  color: #939393;
  line-height: 20px;
}

.status .member .pic {
  display: contents;
}

.status .member-info p.badge {
  margin-top: 0;
  padding: 5px 20px 5px 20px;
  background: #f4970b;
  color: #fff;
  width: fit-content;
  border-radius: 0;
}

a.rytn-white {
  background: white;
  color: #cc2727;
  font-weight: 600;
}

.rytn-white {
  background: white;
  color: #cc2727;
  border: 1px solid #cc2727;
}

a.rytn-pink {
  background: #ffc8c8;
  color: #cc2727;
  border-color: #ffc8c8;
  font-weight: 600;
}

.tab-content select {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #4a5568;
}

/* .select2 {
    width: 100% !important;
  } */

.border-color-input {
  border-color: #4a5568 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

section.viewapplication .entry-img img {
  width: 100%;
  height: 50vh;
}

section.viewapplication .member-info h6 {
  font-size: 16px;
  font-weight: 700;
  color: #4a5568;
  line-height: 20px;
}

section.viewapplication .member-info strong {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

section.viewapplication .member-info input[type="text"] {
  color: #4a5568;
}

section.viewapplication .member-info p {
  color: #6d6d6d;
}

.card-height {
  height: 90px;
}

.submit-idea .member-info h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #000;
}

.form-check-input:checked {
  background-color: #cc2727;
  border-color: #cc2727;
  box-shadow: 0 1px 0 0 #cc2727;
}

form input[type="submit"]:hover {
  background: #df3740;
}

form input[type="submit"] {
  border: 1px solid #d9232d;
  font-size: 16px;
  padding: 0 20px 2px 20px;
  background: #d9232d;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.submit-idea .member-info h6 {
  font-size: 17px;
  font-weight: bold;
  line-height: 23px;
  color: #000;
}

.submit-idea .member-info h5 {
  font-size: 19px;
  font-weight: bold;
  line-height: 23px;
  color: #000;
}

.member-info input::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.submit-idea .member-info span {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #999999;
}

.submit-idea .member-info p {
  font-size: 12px;
  color: #595959;
  font-family: "Roboto", sans-serif;
  font-style: initial;
  line-height: 14px;
}

.submit-idea-container h2 {
  font-size: 24px;
}

.submit-idea .tsform-check {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.submit-idea .tsform-check.checked {
  border: 1px solid #cb2728;
  background: #ffe8e8;
}

.submit-idea .tsform-check.checkboxes {
  padding: 5px 10px;
  border: none;
  box-shadow: none;
  font-weight: 500;
}

.submit-idea .tsform-check.checkboxes .tsform-check-input {
  margin-top: 5px;
  margin-right: 5px;
  height: 16px;
  width: 16px;
  padding: 0 5px 0 5px;
  border-radius: 2px;
}

.submit-idea .tsform-check.checkboxes a,
.submit-idea .tsform-check.checkboxes label,
.submit-idea .tsform-check.checkboxes p {
  color: #4a5568;
}

.submit-idea-container h6 {
  font-size: 18px;
  color: #000;
  /* margin-left: 5px; */
}

.social-advocacy .member-info h6 {
  font-size: 19px;
  font-weight: bold;
  line-height: 21px;
  color: #000;
}

.social-advocacy-container h6 {
  font-size: 19px;
  font-weight: bold;
  line-height: 21px;
  color: #000;
}

.social-advocacy-container p {
  font-size: 17px;
}

.social-advocacy .member-info p {
  font-size: 15px;
  line-height: 21px;
  color: #2f2f2f;
}

.social-advocacy .tsform-check .form-check-input {
  margin-top: 5px;
  margin-right: 5px;
  height: 16px;
  width: 16px;
  padding: 0 5px 0 5px;
  border-radius: 2px;
}

.social-advocacy .tsform-check .form-check-label {
  color: #4a5568;
}

.social-advocacy label {
  color: #000;
}

label.tc {
  font-size: 14px;
}

.imagePreview-file {
  width: 100%;
  height: 180px;
  /*background: #fff url(../img/upload-file.PNG) no-repeat center center / cover;*/
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.imagePreview {
  width: 100%;
  height: 180px;
  /*background: #fff url(../img/upload-img.PNG) no-repeat center center / cover;*/
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.imgUp label {
  color: #fff;
}

.btn-primary {
  background: #00b8ee;
  display: block;
  border-radius: 0;
  box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.2);
  margin-top: -5px;
}

.imgUp {
  margin-bottom: 15px;
  width: 100%;
}

.del {
  position: absolute;
  top: 0;
  right: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}

.imgAdd {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: grey;
  color: #fff;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 30px;
  margin-top: 0;
  cursor: pointer;
  font-size: 15px;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.modal-footer h2 {
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
}

.modal-body h4 {
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
  color: #26ae2b;
}

.modal-body h6 {
  font-size: 12px;
  line-height: 21px;
  font-weight: bold;
}

hr {
  background-color: #e1e1e1;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  -webkit-appearance: none;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #eaeaea;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #293133;
}

.padding-top-50 {
  padding-top: 100px;
}

.pr-0 {
  padding-right: 0 !important;
}

.float-right {
  float: right !important;
}

.text-red {
  color: #d9232d !important;
}

.color-black {
  color: #000 !important;
}

.border-radius-1 {
  border-radius: 6px !important;
}

.judges_dashboard p.font-weight-500 {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #000;
}

.judges_dashboard_content .form-group .form-control {
  color: #898989;
  font-size: 14px;
}

.pic-align {
  height: 190px;
  margin-top: -85px;
  margin-left: 20px;
}

.toggle-sidebar {
  display: block !important;
}

.f100 {
  width: 100%;
  background: #fff;
}
.bgcolor {
  background: none;
}

.text-ucase {
  text-transform: capitalize;
}
.tals_buttons {
  display: flex;
  justify-content: flex-end;
  grid-gap: 1rem;
  margin-top: 20px !important ;
  padding-bottom: 20px !important;
  @media screen and (max-width: 425px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    button:last-child {
      width: 80%;
    }
  }
}
.tals_buttons button {
  background: #fff;
  color: #f35a57;
  padding: 0.5rem 3rem;
  font-size: 1.25rem;
  font-weight: normal;
  border: 1px solid #f35a57;
  @media screen and (max-width: 320px) {
    font-size: 1rem;
  }
}
.tals_buttons button:last-child {
  background: #f35a57;
  color: #fff;
}
.content {
  // border-bottom: 1px solid #e2e2e2;
  min-height: 125px;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  border: 1px solid var(--ck-color-widget-blurred-border);
  align-items: center;
  p {
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.breadcrumbs {
  padding-left: 220px;
  padding-bottom: 20px;
  /* background: #f3f3f3; */
}
.p-lable {
  padding-top: 20px;
  > div div {
    height: 100%;
  }
}
.subtitle {
  font-size: 17px;
  font-weight: 500;
  color: #000;
  margin: 0;
  padding-top: 10px;
}
/*
.tsform-check[aria-describedby]{
  &:hover{
    background: #e1e1e1 !important;
  }
}*/
.tsform-check.disabled-check {
  .mentor-mentee-selected {
    background: #e1e1e1 !important;
  }
}
/*.tsform-check[title]{
  &:hover{
    background: #e1e1e1 !important;
  }
  .mentor-mentee-selected{
  background: #e1e1e1;
  &:hover{
    background: #e1e1e1 !important;
    cursor:none !important;
  }
  }
}*/
.tsform-check.checked {
  border: 1px solid #cb2728;
  background: #ffe8e8;
}
.mentor-mentee-selected {
  padding: 10px;
  background: #fff;
  color: #000;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  //box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
  height: 100%;
}
.mentor-mentee-selected p {
  color: #000;
  font-size: 12px;
  p {
    margin-bottom: 0;
  }
}
//   .mentor-mentee-selected strong{
//       font-size: 18px;
//   }
.mentor-mentee-selected img {
  width: 50px;
  height: 47px;
  margin: 16px 20px 16px 0;
}

.gettitle {
  font-size: 26px;
  font-weight: 700;
  color: #000;
  margin: 0;

  p {
    padding-top: 20px;
  }
}
.form-group {
  padding-top: 20px;
}
.getfooter {
  display: flex;
  justify-content: end;
  padding: 35px 10px;
  margin-top: 3em;
  border-top: 1px solid #e2e2e2;
  .btn {
    border-radius: 3px !important;
    &:hover {
      cursor: pointer;
    }
  }
}
.labsbutton {
  text-align: center !important;
  background: #cc2727 !important;
  color: #fff !important;
  border: 2px solid #cc2727 !important;
  border-radius: 3px !important;
  margin: 15px auto !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 10px 30px !important;
  display: inline-block !important;
  text-transform: unset !important;
}
.labsbutton:hover {
  cursor: pointer !important;
  background-color: #fff !important;
  border: 2px solid #cc2727 !important;
  color: #cc2727 !important;
  a {
    color: #e24d55 !important;
    text-decoration: none !important;
  }
}
.labsbutton a {
  color: #e1e1e1;
}
.edit-btn {
  .edit-button {
    text-align: center;
    background: #cc2727;
    color: #fff;
    border: 2px solid #cc2727;
    border-radius: 3px;
    margin: auto;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 30px;
    display: inline-block;
    text-transform: unset;
    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }
  .edit-button:hover {
    cursor: pointer;
    background-color: #fff;
    color: #cc2727;
    a {
      color: #e24d55;
      text-decoration: none;
    }
  }
  .edit-button a {
    color: #e1e1e1;
  }
}
.startedbutton {
  text-align: center;
  background: #cc2727;
  color: #fff;
  border: 2px solid #cc2727;
  border-radius: 3px;
  margin: auto;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 30px;
  display: inline-block;
}
.startedbutton:hover {
  cursor: pointer;
  background-color: #fff;
  color: #cc2727;
}
.footle {
  background-color: whitesmoke;
  border-top: 1px solid #e2e2e2;
  padding-top: 25px;
}
.getfooter {
  display: flex;
  justify-content: space-between;
}
.teams {
  margin: 10px 0;
  i {
    font-size: small;
  }
  span {
    font-size: 12px;
  }
}
.chl-content {
  width: 75px;
  padding-left: 220px;
  background-color: whitesmoke;
}
.page {
  padding: 3rem;
  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
  h5 {
    margin-bottom: 15px;
  }
  li {
    font-size: 1rem;
    line-height: 1.5;
    a {
      color: #cc2727;
      text-decoration: none;
    }
  }
}

.talc-button {
  text-align: center;
  margin-top: 3rem;
}

.le-tabs {
  .learn-img {
    > div {
      background-color: #ebebeb;
      /*height: 100%;*/
    }
    button {
      width: 100%;
      padding: 0;
    }
    .podcast-detail-cont {
      h2 {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      // h2::first-letter
      // {
      //   text-transform: capitalize;
      // }
    }
  }
  img {
    width: 100%;
  }
}

// .MuiIconButton-root {
//   padding:0!important;
// }

.crt-form {
  display: flex;
  fieldset {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
.portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(85, 98, 112, 0.6);
  &::before {
    content: "";
    background: rgba(85, 98, 112, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
  }
  &:hover::before {
    opacity: 1;
  }
  .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 5px;
    &:hover {
      opacity: 1;
    }
    h4 {
      font-size: 16px;
      color: #fff;
      font-weight: 600;
    }
  }
}
.portfolio-wrap img {
  transition: all ease-in-out 0.3s;
  min-width: 100%;
  margin: auto;
}
.portfolio-wrap:hover img {
  transform: scale(1.2);
}
.sidebar-container {
  transition: all 0.5s;
  position: fixed;
  width: 180px;
  height: 100%;
  left: 0;
  top: 80px;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgb(243, 239, 239);
  color: #1a1a1a;
  @media screen and (max-width: 768px) {
    top: 0;
    position: unset;
  }
  ul {
    li {
      font-weight: 500;
      font-size: 14px;
      margin: 8px 0;
      padding: 0;
      a {
        color: #444;
        padding: 10px;
        display: flex;
        width: 100%;
        > div {
          min-width: 30px;
        }
        &:hover {
          color: #fff;
          text-decoration: none;
        }
      }
      &:hover {
        background-color: #d9232d;
        color: #fff;
        a {
          color: #fff;
        }
        svg {
          fill: #fff;
        }
      }
    }
  }
}
.oderlist {
  ol {
    li {
      margin-bottom: 15px;
      font-size: 1rem;
    }
  }
}

.customBg {
  background-color: "red";
}

.fixed-top {
  background: rgb(0, 184, 238);
  transition: all 0.5s;
  position: fixed;
  padding: 10px;
  align-items: center;
  top: 0;
}
.headerT {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  padding: 10px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center !important;
}
.navbar {
  float: right;
}
.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  margin: 0 5px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #556270;
  white-space: nowrap;
  transition: 0.3s;
  border-radius: 4px;
  float: inline-end;
}
.logo img {
  min-height: 40px;
  height: 40px;
}
.avat-pic {
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 425px) {
    justify-content: start;
  }
  // @media screen and (max-width: 320px) {
  //   > div:first-child {
  //     width: 20px;
  //     height: 20px;
  //   }
  // }
  .logout-pic {
    float: right;
    @media screen and (max-width: 480px) {
      float: left;
    }
  }
  button {
    color: #fff;
    &:hover {
      background: unset;
    }
  }
}
.desktop-menu {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.mobi-menu {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    > div {
      padding: 0 10px 0 0;
    }
  }
}
.learning-labs {
  button {
    &.Mui-selected {
      background-color: #d9232d;
      span {
        color: #fff !important;
      }
    }
    span {
      color: #000;
      font-weight: 600;
      font-size: 1rem;
      text-transform: none;
    }
  }
}
.del-btn:hover {
  &.MuiIconButton-root:hover {
    background-color: unset;
  }
}
.project {
  background-color: whitesmoke;
  margin: 10px;
  width: 75px;
  padding-left: 220px;
}
.socialphy {
  width: 100%;
}
.Conditions {
  border-top: 1px solid #e2e2e2;
}
.bts-buttons {
  display: flex;
  justify-content: space-between;
  float: end;
  border-top: 1px solid #e2e2e2;
  margin: 30px 0;
}
.savedraft {
  display: flex;
  justify-content: end;
  text-align: center;
  background: #fff;
  color: #cc2727;
  border: 2px solid #cc2727;
  border-radius: 3px;
  margin-right: 15px;
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 30px;
  display: inline-block;
}
.savedraft:hover {
  cursor: pointer;
  background-color: #fff;
  color: #cc2727;
}
.submit {
  display: flex;
  justify-content: end;
  text-align: center;
  background: #cc2727;
  color: #fff;
  border: 2px solid #cc2727;
  border-radius: 3px;
  margin: auto;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 30px;
  display: inline-block;
}
.submit:hover {
  cursor: pointer;
  background-color: #fff;
  color: #cc2727;
}
.Cancle {
  display: flex;
  justify-content: start;
  margin-top: 10px;
  text-align: center;
  background: #fff;
  color: #cc2727;
  border: 2px solid #cc2727;
  border-radius: 3px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 30px;
  display: inline-block;
}
/****prathima**/

.sizeauto {
  padding: 12px;
}
a {
  text-decoration: none;
}

.breadcrumbs {
  padding-left: 220px;
  padding-bottom: 20px;
  background-color: #f3f3f3;
  /* background: #f3f3f3; */
}

.form_submit {
  color: rgb(201, 73, 73);
  background-color: #fff;
  padding-top: 12px;
}
.rytn {
  text-align: center;
  background: #cc2727;
  color: #fff;
  border: 2px solid #cc2727;
  border-radius: 3px;
  margin: auto;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 30px;
  display: inline-block;
}
a {
  text-decoration: none;
}
label {
  // margin-bottom:30px;
  margin-right: 10px;
}
.btnend {
  display: flex;
  justify-content: end;
  padding-top: 18px;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: none;
}
.m20 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.mleft {
  margin-left: 1rem !important;
}
.p100 {
  font-size: 16px;
  font-weight: 700;
  color: #939393;
}

.avatar-pic {
  display: inline-block;
  position: relative;
  .changeProfileImg {
    position: absolute;
    bottom: 13px;
    right: 2px;
    background: #fff;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;
    box-shadow: 0 1px 2px #000;
  }
}

.new-addresst {
  .add-bene {
    top: -5px;
    background-color: #fff;
    margin: 0 10px;
    padding: 0 5px !important;
  }

  .input-field {
    margin-top: 0 !important;
    div {
      input {
        border-radius: 4px;
        height: 40px;
      }
    }
  }
  .newAddr-container {
    .addr-select-container {
      > div {
        background: #fff;
      }
    }
  }
}
.new-phone {
  > div {
    background: #fff;
  }
}

.midimg {
  text-align: center;
}
.tal-team-members {
  label {
    padding-bottom: 0;
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e1e1e1;
    border-bottom: none;
    background: #fff;
    margin-bottom: 0;
    &:last-child {
      border-bottom: 1px solid #e1e1e1;
    }
    button {
      &:hover {
        background-color: unset;
      }
    }
  }
}
.textarea-ui {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid rgb(192, 192, 192);
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.87);
  }
  &:focus-visible {
    outline: 1px solid rgb(46, 46, 209) !important;
  }
}
.tals_button span {
  background: #cc2727;
  color: #ffff;
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #f35a57;
  text-transform: none;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;

    width: 100%;
    padding: 0.5rem 2rem;
  }
}
.linktag a {
  text-decoration: none;
  color: #1967e5 !important;
  cursor: pointer;
}
.upload-pic {
  width: 30%;
  padding: 10px;
  display: block !important;
  margin: 0 auto 15px !important;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.view-btn {
  float: right;
  @media screen and (max-width: 768px) {
    float: left;
  }
}
.image-gallery-slide.center {
  video {
    height: 185px !important;
  }
}
.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 0 10px;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px;
  width: 60px;
}
.view-btn {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  @media screen and (max-width: 768px) {
    float: left;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
}
.edit-btn {
  .edit-button {
    text-align: center;
    background: #cc2727;
    color: #fff;
    border: 2px solid #cc2727;
    border-radius: 3px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 30px;
    display: inline-block;
    text-transform: unset;

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }
  .edit-button:hover {
    cursor: pointer;
    background-color: #fff;
    color: #cc2727;
    a {
      color: #e24d55;
      text-decoration: none;
    }
  }
  .edit-button a {
    color: #e1e1e1;
  }
}
/*
div[role=tooltip]{
> div
{
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.8);
}
// > div::first-letter
//       {
//         text-transform: capitalize;
//       }
}*/
.rating_ideas label {
  padding-right: 10px;
  margin-right: unset;
}
.labsbutton_disable {
  background-color: unset !important;
  border: unset;
  a {
    color: rgba(0, 0, 0, 0.26);
  }
}
.btn-create {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.continues-btn span {
  font-size: 1.3;
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  border-radius: 5px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  padding: 16px 25px;
}
.continues-btn:hover {
  background-color: unset !important;
}

.cancle-btn span {
  font-size: 1.3;
  color: #dc3545;
  background-color: #fff;
  border: 2px solid #dc3545;
  border-radius: 5px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  padding: 16px 30px;
}
.cancle-btn:hover {
  background-color: unset !important;
}
.name-hovr {
  color: #303f9f;
  text-decoration: none;
  &:hover {
    color: #303f9f;
    text-decoration: none !important;
  }
}
.custom_errors {
  margin-top: 20px !important;
  font: 500 12px !important;
  color: red;
}
