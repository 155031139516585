html {
  /* font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif; */
  font-size: 14px;
}

.mobil-temp {
  position: relative;
  width: 50%;
  margin: 0 auto;
  border: 1px solid #e5e5e5;
}

@media screen and (max-width: 600px) {
  .email-masthead_name img {
    width: 180px !important;
  }
  .mobil-temp {
    position: relative;
    width: 80%;
    margin: 0 auto;
    border: 1px solid #e5e5e5;
  }
}

.mobil-temp .sub {
  margin-top: 20px;
}

.mobil-temp .content-cell {
  padding: 0 !important;
}

.mobil-temp .content-cell p:nth-child(1) {
  padding: 0 !important;
  margin-bottom: 20px;
}
.mobil-temp .content-cell p {
  width: 84% !important;
  padding: 0 20px;
}

.title-name {
  padding: 0 !important;
}
.title-name span {
  width: 230px;
  min-height: 60px;
  line-height: 20px;
  padding: 10px;
  font-size: 14px;
  color: #727272;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
  font-weight: 400;
  /* border: 1px solid #c8c8c8; */
  align-items: center;
  display: flex;
  justify-content: center;
}

h5 {
  font-size: 1.28571429em;
  font-weight: 700;
  line-height: 1.2857em;
  margin: 0;
}

.card {
  font-size: 1em;
  overflow: hidden;
  padding: 0;
  border: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  height: 200;
  width: 200;
}

.card-inverse .btn {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.card {
  position: relative;
  overflow: hidden;
}

.whenhovered {
  position: absolute;
  top: -100%;
  right: 0;
  background: #fff;
  width: 100%;
  height: 100%;

  text-align: center;
  color: #fff !important;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.card:hover .whenhovered {
  top: 0%;
  background: rgba(0, 0, 0, 0.75);
}
.force-mobile {
  width: 750px;
  max-width: 750px;
  max-height: 1334px;
  margin: 0 auto;
  border-style: none;
  border-color: inherit;
  border-width: 0px;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 0 0;
  display: block;
}
