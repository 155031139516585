@import "./variables";

@mixin meta-style {
  color: $color-meta-text;
  font-weight: map-get($font-weights, medium);
}

@mixin input-fields {
  & div {
    // &.MuiInput-underline:after {
    //   border-bottom: 1px solid #17a40d;
    // }
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: transparent;
    &.MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none !important;
    }

    &:focus {
      border-bottom: none !important;
      background: transparent;
    }
    &:hover {
      // border-bottom: none !important;
      background: transparent;
    }
    // border-bottom: 2px solid #f44336;
    &:before {
      border-bottom: none;
    }
    // &:hover:not(.Mui-disabled):before {
    //   border-bottom: none;
    // }
    input {
      height: 30px;
      border: 1px solid #ccc;
      background: #fff;
      // border-radius: 3px;
      // border: none;
      padding: 7px 15px;
      color: #616161;
    }
    fieldset {
      border: none;
    }
  }
}



@mixin validations {
  display: table-row;
  width: 100%;
  color: #f35a57;
  font-size: 12px;
  text-align: start;
}

@mixin submitButton {
  background: #f35a57 !important;
  width: 100%;
  margin: 10px 0;
  font-size: 14px;
  color: #fff !important;
  font-weight: 600;
}

@mixin condition($check: false) {
  @if $check {
    color: $color-green;
  } @else {
    color: $color-light-grey;
  }
}

@mixin selectedCloth($cloth: true) {
  @if $cloth {
    padding: 10px;
    border: 2px solid rgb(0, 81, 255);
    background: rgb(67, 126, 255);
    cursor: pointer;
    border-radius: 25px;
  }
}

@mixin selectedFood($food: true) {
  @if $food {
    padding: 10px;
    border: 2px solid rgb(0, 81, 255);
    background: rgb(67, 126, 255);
    cursor: pointer;
    border-radius: 25px;
  }
}

@mixin selectedBaby($baby: true) {
  @if $baby {
    padding: 10px;
    border: 2px solid rgb(0, 81, 255);
    background: rgb(67, 126, 255);
    cursor: pointer;
    border-radius: 25px;
  }
}

@mixin selectedHome($home: true) {
  @if $home {
    padding: 10px;
    border: 2px solid rgb(0, 81, 255);
    background: rgb(67, 126, 255);
    cursor: pointer;
    border-radius: 25px;
  }
}

@mixin selectedHygiene($hygiene: true) {
  @if $hygiene {
    padding: 10px;
    border: 2px solid rgb(0, 81, 255);
    background: rgb(67, 126, 255);
    cursor: pointer;
    border-radius: 25px;
  }
}
