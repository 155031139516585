.searchbar-dr {
  margin-top: 5px;
  padding: 5px;
  // height: 53px;
  // border: 1px solid #ebebeb;
  background-color: #f6f6f6;
  display: flex;

  fil input[type="text"] {
    padding: 4px 0 4px 10px;
    // background-color: #f6f6f6;
    width: 231px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
  }

  input[type="text"]:focus {
    min-width: 200px;
    max-width: 100%;
    // position: absolute;
    // top: -24px;
    // height: 30px;
    background-color: #f6f6f6;
    padding: 5px;
  }
}

.myDonations-container {
  // margin: 0 auto;
  // max-width: 1170px;

  // @media screen and (max-width: 960px) {
  //   padding: 0 10px !important;
  // }
  .MuiTableContainer-root.container {
    top: 0;
  }
  .table-fixed {
    .MuiTable-stickyHeader {
      border-collapse: collapse;
    }
  }
  .MuiTableCell-paddingNone {
    padding-left: 15px;
  }

  .footer-container {
    display: none;
  }

  .hamburger-icon {
    position: fixed;
    z-index: 10;
    background-color: white !important;
    border: 2px solid #eee;
    padding: 12px 14px;
    margin: 30px 0 0 12px;
    left: 0;
  }

  .hamburger-icon:hover {
    background-color: var(--tal_primary)!important;
    border: 2px solid white;
    color: white !important;
  }

  .container {
    padding: 0 10px;
  }

  .table-fixed {
    .MuiIconButton-root {
      text-align: left !important;
    }
  }

  .MuiTableContainer-root {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    position: sticky;
    background: white;
    padding: 0 4px 0 0 !important;
    border: 4px solid white;
    border-right: none;
    bottom: 0;
    top: 160px;

    .MuiTablePagination-root {
      left: 0;
      right: 0;
    }
  }

  margin: 0 auto;
  .title {
   
    font-size: 18px;
    font-weight: 600;;
  }
}

.table-header-text {
  color: #000 !important;
  background-color: #fddad8 !important;

  table {
    table-layout: fixed;
  }

  span {
    font: 500 14px "Roboto";
  }
  svg {
    color: #000;
  }
  span {
    &:focus {
      color: #000 !important;
    }
    &:hover {
      color: #000 !important;
    }
  }
}

.title {
  background-color: white;
text-align: center !important;
  padding: 10px;
  color: var(--tal_primary);
}

@media screen and (max-width: 480px) {
  .table-container {
    max-width: 100% !important;
  }

  @media screen and (max-width: 600px) {
    table {
      min-width: 0;
    }
  }
}
