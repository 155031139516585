.billing-input {
  border: 1px solid #dadada !important;
  border-radius: 4px;
  padding: 0 10px !important;
  height: 61px !important;
  line-height: 60px !important;
  input {
    border: none;
    height: 61px !important;
    background: none;
  }
}

.saveBtn {
  background-color: var(--tal_primary)  !important;
  width: 200px;
  padding: 10px !important;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}
