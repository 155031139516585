.barnav {
  background-color: white;
  padding: 10px 0;
  margin-bottom: 10px;
  margin-top: -25px;
  position: sticky;
  top: 85px;
  border-bottom: 2px solid rgb(246, 247, 251);
  z-index: 1;

  .DayPickerKeyboardShortcuts_show {
    border: none !important;
  }
  .DateRangePickerInput__withBorder {
    border-radius: 4px !important;
    border: 1px solid #cbcbcb !important;
  }
  .DateRangePickerInput__withBorder button {
    margin: 0 !important;
    min-width: 30px !important;
  }

  .DateRangePickerInput__withBorder .DateInput {
    width: 100px;
  }

  .DateRangePickerInput__withBorder .DateInput_input {
    font-size: 14px;
    font-weight: 400;
  }
  .appbar-icon {
    margin-top: 10px;
  }
  .DateRangePickerInput_calendarIcon {
    border: none !important;
    margin: 0;
  }
  .MuiInputLabel-shrink {
    transform: translate(12px, -4px) scale(0.75) !important;
  }
  @media screen and (max-width: 960px) {
    position: relative;
    top: 2px;
    width: 100%;
    // z-index: 10;
  }

  h4 {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: var(--tal_primary);
    margin: 0;
  }

  .search-right {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
  }
}

.barnav button:nth-child(1) {
  color: var(--tal_primary);
  min-width: 50px;
  height: 50px;
  border: 2px solid var(--tal_primary);
  border-radius: 100%;
  margin-right: 10px;
}

.bar-main {
  display: flex;
  margin-bottom: 5px;
  padding: 6px;
  justify-content: space-between;
  position: sticky;
  top: 165px;
  // width: 100%;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  .result {
    margin: 0;
    margin-top: 8px;
    @media screen and (max-width: 600px) {
      padding: 10px 0;
      margin: 0;
    }
    @import "../../sass/variables";
    @import "../../sass/mixins";

    .plasma {
      // width: 60%;
      padding: 25px;
      display: inline-block;
      @media (max-width: 600px) {
        width: 100%;
        padding: 20px;
      }
    }

    .close-organizatin {
      margin: 0;
      :hover {
        color: rgb(205, 38, 39);
      }
    }
    .org-container {
      padding-left: 20px;
      .org-title {
        color: #058ad0;
        font: 600 20px "Roboto";
        text-transform: uppercase;
      }
    }
  }
}
.admin-head {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &:last-child {
    gap: 20px;
    flex-wrap: wrap;
    @media screen and (max-width:425px) {
      > div {
        flex: 100%;
      }
    }
  }
  .search-admin {
    background: #eee;
    padding: 12px;
    border-radius: 5px;
    svg {
      float: right;
    }
  }
}
.search-admin-all {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .admin-select {
    flex: 15%;
    @media screen and (max-width:768px) {
      flex: 25%;
    }
    @media screen and (max-width:425px) {
      flex: 40%;
    }
  }
}
.admin-container {
  tr {
    th {
      border-bottom: unset;
      border: unset;
    }
  }
  .adminsrn {
    display: flex;
    align-items: center;
    a {
      color: black
    }
  }
}
.podcast-detail-conts {
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
}
