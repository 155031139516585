.donation-payment-container {
  .pay-mian {
    width: 250px;
    border-radius: 4px;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  .sr-input {
    background: transparent;
  }
  .input-container {
    display: inline-block !important;
    text-align: start !important;
    width: 100%;
  }

  h4 {
    color: #ef6c00;
    border-bottom: 1px solid rgb(246, 247, 251);
    padding: 20px;
    margin: 0;
  }
  .card-cvv {
    img {
      position: absolute;
      right: 2px;
      top: 1px;
      width: 65px;
      @media screen and (max-width: 600px) {
        width: 45px;
      }
    }
  }
  .container-center {
    padding: 0 30px;
  }

  .__PrivateStripeElement {
    top: 9px;
  }

  .card-grid {
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    &.last-name {
      height: 48px !important;
      vertical-align: bottom !important;

      ::placeholder {
        color: #ccc;
      }
    }
    .cvc-image {
      position: absolute;
      width: 19%;
      right: 3px;
      top: 3px;
    }
    &.cvc-container {
      position: relative;
      .cvc-img {
        position: absolute;
        top: 26px;
        right: 4px;
        width: 16%;
      }
    }
  }

  .title {
    font: 600 18px "Roboto";
    color: #333;
    display: block;
    width: 100%;
    text-align: start;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  .choose-payment {
    padding: 8px 0;
  }
  .payment-btns {
    text-align: left;
    padding: 20px 30px;
    p {
      margin-bottom: 0 !important;
    }
    .stripe-btn {
      width: 15%;
      margin-right: 10px;
      border-radius: 8px;
      padding: 6px;
      display: inline-block;
      background: #fff;
      border: 1px solid #78c4f7;
      position: relative;
      &:first-child {
        margin-right: 30px;
      }
      &:last-child {
        // margin-left: 30px;
        // height: 61px;
        position: relative;
      }
      .check-icon {
        color: #41a9ed;
        position: absolute;
        top: -9px;
        right: -7px;
        background: #fff;
      }
    }
  }
  .stripe-card-details {
    width: 100%;
  }
  .my-payment-btn {
    width: 50%;
    // margin: 10px;
    background: var(--tal_primary) ;
    padding: 10px;
    color: #fff;
    // font: 500 15px "Roboto";
    // display: block;
  }
  .card-adv {
    text-align: center;
    margin: 10px 0;
    display: flex;
    img {
      width: 70px;
      height: 38px;
      padding: 5px;
      margin-right: 10px;
      box-shadow: (0 0 9px #e8eaf5);
      background-color: #ffffff;
      border-radius: 4px;
    }
  }
}
