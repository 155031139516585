@import "../../sass/variables";
@import "../../sass/mixins";

.cash-request-from {
  .MuiOutlinedInput-input {
    padding: 15px;
  }
}
.drif_data {
  .MuiOutlinedInput-multiline {
    padding: 0 !important;
  }
}


.make-req-container .request-form .input-field div {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  input {
    background: transparent !important;
    :hover {
      border-bottom: 1px solid #ccc !important;
    }
  }
}

.placeholder {
  position: relative;
  top: -12px;
  label {
    margin: 0;
    position: relative;
    top: 19px !important;
    color: #828282;
    z-index: 1;
  }
}

.updated-ui-content {
  background-color: #f1f1f1;
  padding: 10px;
  .MuiCardActionArea-root {
    border: 1px solid rgb(241, 241, 241);
    cursor: auto !important;
  }

  h3 {
    font-size: 22px;
    padding: 10px;
    font-weight: bold;
  }
  p {
    font-size: 12px;
  }
  .MuiCardContent-root {
    background: #fafafa;
  }
}

.update-req-container {
  .MuiCardMedia-root {
    background-size: initial !important;
    background-position: inherit !important;
  }
  .MuiCardContent-root {
    background-color: #fafafa;
    padding: 10px;
    p {
      margin-bottom: 0;
    }
  }
}
.request-container {
  width: 50%;
  @media screen and (max-width: 960px) {
    width: 90%;
  }
  margin: 70px auto 0 auto !important;

  border-radius: 4px;
  background-color: white;

  height: 70vh;
  overflow-y: auto;
}

.make-req-container {
  flex-wrap: nowrap !important;
  label {
    top: -6px;
    background: #fff;
    left: 12px;
    padding: 0 8px;
    transform: none !important;
  }
  .justi-label {
    display: inline-block;
    font-size: 13px !important;
    // position: absolute;
    background: #fff;
    // padding: 0 8px !important;
    // top: -8px;
    // left: 12px;
    color: #aaa;
  }
  & .req-main-title {
    padding: 10px 20px;
    position: sticky;
    top: 0;
    z-index: 2;
    h2 {
      font-weight: 400;
      font-size: 18px;
      text-align: start;
      line-height: 32px;

      .close-modal {
        padding: 9px;
        border-radius: 100%;
        height: 28px;
        display: grid;
        place-content: center;
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
  .request-form {
    padding: 20px;

    .custom-error {
      @include validations;
    }

    p {
      font-family: $font-regular;
      font-size: 16px;
      // margin: 8px 0;
      padding: 0;
    }
    .radio-container {
      width: 100%;
      label {
        width: 32%;
      }
    }
    .input-field {
      @include input-fields;
      margin: 5px 0;
    }
    .cash {
      width: 100%;
    }
    .currency {
      @include input-fields;
      border: 1px solid #ccc;

      // margin-left: 4px !important;
      div {
        padding-bottom: 0;
      }
      &:before {
        border-bottom: 2px solid #f44336;
      }
      div {
        height: 39px;
        // border-radius: 3px;
        border: none !important;
        padding-left: 15px;
        color: #616161;
      }
    }
    .startdate {
      // width: 49%;
      margin-right: 4px !important;
      div {
        border: 1px solid #ccc;
        background: transparent;
        div {
          border: none;
        }
        input {
          border: none !important;
        }
      }
    }
    .duedate {
      width: 49%;
      margin-left: 4px !important;
      div {
        border: 1px solid #ccc;
        background: transparent;
        div {
          border: none;
        }
        input {
          border: none !important;
        }
      }
    }
    .title {
      width: 100%;
    }
    .desc {
      margin-top: 10px;
      width: 100%;
      font-family: $font-regular;
      background: transparent;
      height: 46px;
      border-radius: 3px;
      border: 1px solid #ccc;
      border-bottom: 2px solid #f44336;
      color: #616161;
      &:focus {
        border-bottom: 2px solid #303f9f;
      }
    }
    .requestedfor {
      width: 100%;
    }
    .quantity {
      width: 49%;
      margin-right: 4px !important;
    }
    .size {
      width: 49%;
      margin-left: 4px !important;
    }
    .time {
      width: 49%;
      margin-right: 4px !important;
    }
    .hours {
      width: 49%;
      margin-left: 4px !important;
    }
    .MuiTextField-root {
      margin: 6px 0;
    }
    .MuiGrid-spacing-xs-8 > .MuiGrid-item {
      padding: 0;
    }
    .address {
      width: 100%;
      .MuiOutlinedInput-adornedEnd {
        padding: 0;
      }
      .my-address {
        background: transparent;
        border: 1px solid #eee;
        border-radius: 3px;
        border-bottom: 2px solid #f44336;
        div {
          & fieldset {
            border: none !important;
            &:hover {
              border: none !important;
            }
          }
        }
      }
      input {
        height: 33px;
        padding: 7px 15px;
        color: #616161;
      }
    }
    .submit-btn {
      @include submitButton;
    }
  }
}

.admin-title-length {
  position: absolute;
  right: 20px;
  top: 16px;
  background: #fff;
  padding: 0px;
}
