div {
  font-size: 14px;
  margin: 0;
}

table {
  width: 100%;
  // padding: 10px 0px 10px 0px;
}
.hedear_bg {
  background-color: #283e4a;
  padding: 10px;
  width: 100%;
  text-align: center;
  padding-bottom: 40px;
  img{
    width: 260px;
    padding: 20px;
    margin-bottom: 25px;
    @media only screen and (max-width: 350px) {
      padding: 20px 0;
    }
  }
}

.section_bg {
  background-color: #f5f5f5;
  width: 100%;
}
.body_color {
  border: 1px solid rgb(245, 245, 245);
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  height: auto;
  text-align: center;

  line-height: 35px;
  position: relative;
  top: -30px;
}


.body_color table {
  width: 100%;
}


.body_color table tr td {
  width: 50%;
  text-align: left;
}

.body_color h1 {
  color: #283e4a;
}


.text_center td {
  text-align: center !important;
}

.body_color small {
  color: #717171;
  padding-right: 10px;
  font-weight: bold;
}
.body_color img {
  padding-right: 10px;
  vertical-align: middle;
}
.body_color strong {
  color: #cb2624;
  font-size: 16px;
}
.body_color span {
  color: #717171;
}

.primary_color {
  color: #cb2624;
}
.secondary_color {
  color: #283e4a;
}
.footer {
  text-align: center !important;
  margin: 0 auto;
  padding: 10px 40px;
  background-color: #f5f5f5;
}
.footer p:nth-of-type(1) {
  font-weight: 600;
}
.footer p:nth-of-type(2) {
  border-bottom: 1px solid #c2c2c2;
  padding: 10px 0;
  margin-bottom: 10px;
}
.footer h2 {
  color: #1c1c1c;
}
.footer p {
  color: #3b3b3b;
  font-size: 11px;
  line-height: 16px;
}
.footer img {
  width: 1.5%;
  padding: 0 3px;
}
.footer p:last-child {
  color: #332c41;
  font-weight: 600;
  padding: 10px 0;
}
