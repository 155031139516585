.thankyou-page {
  min-height: 76vh;
  display: grid;
  place-content: center;
  // @media screen and (max-width: 1170px) {
  //   // margin: 0 20px !important;
  //   padding:0 10px !important;
  // }

  .home-btn {
    width: 50% !important;
  }
  p:nth-child(5) {
    width: 100% !important;
    padding-bottom: 10px !important;
    span {
      padding: 0 20px;
      font-size: 1.2rem !important;
      font-weight: 500 !important;
    }
  }
  p:nth-child(6) {
    margin: 0 auto !important;
  }
  .trans-num {
    border-top: 2px dashed#ccc;
    border-bottom: 2px dashed #ccc;
    padding: 10px 0 !important;
    margin-top: 15px !important;
  }

  .amount:last-child {
    text-transform: uppercase !important;
  }
}

.thank-container {
  // max-width: 1170px;
  // margin: 0 auto;
  // text-align: center;
  // padding-bottom: 20px;

  // @media screen and (max-width: 1170px) {
  //   padding: 0 10px;
  // }

  // img {
  //   width: 50% !important;
  // }
  .success-text {
    font: 900 20px "Roboto" !important;
    margin: 20px auto !important;
    color: #555 !important;
    text-align: center;
  }

  .amount {
    text-align: center;
    margin: 10px 0;
    font: 500 18px "Roboto";
  }

  .trans-num {
    text-align: center;
    margin: 10px 0;
    font: 500 18px "Roboto";
    color: #ccc;
  }

  .kindness-text {
    font: 600 20px "Roboto" !important;
    margin: 20px auto !important;
    text-align: center;
  }

  .home-btn {
    background: var(--tal_primary) ;
    color: #fff;
    margin: 0 auto;
    width: 35%;
    text-align: center;
  }

  .home-btn:hover {
    background: var(--tal_primary) ;
    color: #fff;
  }
}
