.custom_error {
    font: 500 12px "Roboto" !important;
    color: red !important;
    margin-bottom: 8px;
  }
  
  .bg_normal {
    border: 1px solid #ccc !important;
    outline: unset;
  }
  
  .bg_green {
    border: 1px solid #ccc !important;
    outline: unset;
  }
  
  .custom_error {
    font: 500 12px "Roboto" !important;
    color: red !important;
    margin-bottom: 8px;
  }
  .bg_normal {
    border: 1px solid #ccc;
    outline: unset;
  }
  
  .bg_red {
    border: 1px solid #fd2a2a !important;
    outline: unset;
  }
  .plasma_input {
    border-radius: 4px;
    
    fieldset {
      border-color: unset;
      border-style: unset;
      border-width: unset;
    }
    button {
      margin-top: -12px;
    }
  }
  .blood_grp {
    width: 100%;
    padding: 10px;
    label {
      top: 8px;
      background: #fff !important;
    }
  }
  
  .date_b {
    label {
      background: #fff;
    }
    div {
      padding-left: 15px;
    }
    div:before {
      border-bottom: none !important;
    }
  }
  
  .user_btn {
    &:hover {
      background-color: unset !important;
    }
    span {
      font-size: 11px !important;
      padding: 10px;
      border-radius: 23px;
    }
  }
  .btn_render span {
    background-color: #e6e6e6;
    &:hover {
      box-shadow: 0px 0px 5px #666;
    }
  }
  .active_btn span {
    background-color: rgb(243, 90, 87);
    color: #fff !important;
  }
  
    
  
  .plasma_input {
    border-radius: 4px;
    margin-bottom: 6px !important;
  
    fieldset {
      border-color: unset;
      border-style: unset;
      border-width: unset;
    }
  
    label {
      background: #fff;
    }
  }
  
  
  
  .date_b {
    label {
      background: #fff;
    }
  
    div {
      padding-left: 15px;
    }
  
    div:before {
      border-bottom: none !important;
    }
  }
  // .blood_grp {
  //   width: 100%;
  //   padding: 10px;
  //   label {
  //     // top: 17px;
  //     background: #fff;
  //     // left: 9px;
  //   }
  // }
  .location_txt{
    display: flex;
    color: #777;
    p{
      color: #777;
    }
  }
  .fill_btn{
    svg,p{
      color: #F35A57;
      
    }
    color: #F35A57;
    border: 1px solid #F35A57;
    background-color: #FFF6F6;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    p{
      font-size: 18px;
      font-weight: 500;
    }
    >div:first-child{
      display: flex;
      gap: 15px;
    }
  }
  .app_txt_gu{
    p:first-child{
      margin-bottom: 15px;
    }
    p:not(:first-child){
      color: #777;
      margin-bottom: 10px;
    }
  }