@import "../../sass/mixins";
@import "../../sass/variables";

$green: #22b573;
$grey: #555;
$transitionSpeed: 200ms;

@mixin transition($time, $type, $property) {
  transition: $time $type $property;
  -moz-transition: $time $type $property;
  -webkit-transition: $time $type $property;
}

// Create mixin for transition property with transition dela
@mixin transition-with-delay($tim, $dela, $typ, $propert) {
  transition: $tim $dela $typ $propert;
  -moz-transition: $tim $dela $typ $propert;
  -webkit-transition: $tim $dela $typ $propert;
}

.title-length {
  font-size: 18px;
  margin-left: 5px;
  position: absolute;
  top: 21px;
  color: #ccc;
  right: 1px;
  padding: 9px;
  background: #fff;
}
.plasma-container {
  .stepper-title {
    font: 600 26px $font-regular;
    text-align: center;
    color: var(--tal_primary) ;
  }
  label {
    background: #fff;
    padding: 0 10px;
  }
  .container-center {
    padding: 30px;
    background: #fff;
    min-height: 500px;
    text-align: left;
    border-radius: 4px;
    @media screen and (max-width: 600px) {
      padding: 20px 10px;
    }
  }
  .sub-hr {
    width: 50px;
    border: 1px solid #d34040;
    margin: 14px auto;
  }
  .stepper-subheader {
    font: 400 16px "Roboto";
    text-align: center;
    font-weight: bold;
  }

  .plasma-otp {
    margin: 30px 0;

    input[type="checkbox"] {
      opacity: 0;
      z-index: -1;
    }

    input[type="checkbox"] + label {
      width: 30px;
      height: 30px;
      position: relative;
      margin: auto;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      text-indent: -999px;
      overflow: hidden;
      border: 1px solid green;
      cursor: pointer;
    }

    input[type="checkbox"]#checkbox-c + label {
      border: 2px solid #aaa;
      background-color: transparent;
      @include transition(0.8s, ease, border);
    }

    input[type="checkbox"]#checkbox-c:checked + label {
      border: 5px solid #aaa;
      background: green;
      @include transition(0.5s, ease, all);
    }

    input[type="checkbox"]#checkbox-d + label {
      border: 2px solid #aaa;
      background-color: transparent;
      @include transition(0.8s, ease, border);
    }

    input[type="checkbox"]#checkbox-d:checked + label {
      border: 5px solid #aaa;
      background: green;
      @include transition(0.5s, ease, all);
    }

    input[type="checkbox"]#checkbox-e + label {
      border: 2px solid #aaa;
      background-color: transparent;
      @include transition(0.8s, ease, border);
    }

    input[type="checkbox"]#checkbox-e:checked + label {
      border: 5px solid #aaa;
      background: green;
      @include transition(0.5s, ease, all);
    }

    input[type="checkbox"]#checkbox-f + label {
      border: 2px solid #aaa;
      background-color: transparent;
      @include transition(0.8s, ease, border);
    }

    input[type="checkbox"]#checkbox-f:checked + label {
      border: 5px solid #aaa;
      background: green;
      @include transition(0.5s, ease, all);
    }

    input[type="checkbox"]#checkbox-g + label {
      border: 2px solid #aaa;
      background-color: transparent;
      @include transition(0.8s, ease, border);
    }

    input[type="checkbox"]#checkbox-g:checked + label {
      border: 5px solid #aaa;
      background: green;
      @include transition(0.5s, ease, all);
    }
    span {
      font: 500 16px "Roboto";
      margin-top: 5px;
      margin-left: 10px;
    }
  }
  .phonebutton {
    width: 50%;
    @media screen and (max-width: 600px) {
      width:100%;
    }
  }
  .vebutton {
    display: flex;
    position: relative;
    margin-left: 10px;
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    .verifyicon {
      position: relative;
      right: 47px;
      color: green;
      font-size: 36px;
      top: -14px;
      @media screen and (max-width: 600px) {
        top: 14px;
        position: absolute;
        right: 5px;
      }
    }
  }
  .otp-input {
    width: 38%;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .otp-btn {
    background: var(--tal_primary) !important;
    color: #fff;
    padding: 19px;
    vertical-align: bottom;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    left: -3px;
    :hover {
      background: var(--tal_primary) !important;
    }
  }

  .edit-number {
    background: var(--tal_primary) !important;
    color: #fff;
    padding: 20px;
    vertical-align: bottom;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    right: 39px;
    width: 140px;
    :hover {
      background:var(--tal_primary) !important;
    }
    @media screen and (max-width: 600px) {
      right: 0;
      border-radius: 4px;
      top: 12px;
      width: 100%;
    }
  }
  .savebtn {
    background: var(--tal_primary) !important;
    color: #fff !important;

    padding: 15px 20px;
    margin: 30px 0;
    display: inline-block;
    margin-left: 10px;
    @media screen and (max-width: 600px) {
      width: 98%;
    }
  }

  .PhoneInput {
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .resend-otp {
    float: right;
    color: #ccc;
  }

  input[type="checkbox"].tick-checkinput[type="checkbox"] + label {
    position: absolute;
    width: 60px;
    height: 60px;
    border: 10px solid $grey;
    border-radius: 100%;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
    transition: all ease-out $transitionSpeed;
    text-indent: 90px;
    font: normal normal 30px/60px "Helvetica";
    white-space: nowrap;
    color: $grey;
    user-select: none;

    &:after {
      content: "";
      position: absolute;
      width: 0px;
      height: 25px;
      border-bottom: 10px solid $green;
      border-left: 10px solid $green;
      top: 25%;
      left: 50%;
      transform-origin: bottom left;
      transform: rotate(-45deg);
      opacity: 0;
      transition: all ease-out $transitionSpeed;
    }
  }

  input[type="checkbox"].tick-check:checked + label {
    border: 10px solid $green;

    &:after {
      opacity: 1;
      width: 70px;
    }
  }

  #chk {
    display: none;
  }

  .MuiStepIcon-active,
  .MuiStepIcon-completed,
  .MuiStepLabel-active,
  .MuiStepLabel-completed {
    color: #5bca7a !important;
  }
  .request-step-one,
  .request-step-two {
    background-image: url("/images/add-req-1.png");
    background-position: right bottom;
    background-size: 40%;
    background-repeat: no-repeat;
    @media screen and (max-width: 960px) {
      background-image: none;
    }
  }
  .plasma-request-form {
    width: 60%;

    .flname {
      width: 100%;
      .pass-container {
        display: inline-grid;
        width: 100%;
        .pass-field {
          width: 99%;
        }
      }
      & .names {
        display: inline-block;
        min-width: 48%;
        .signup-input {
          width: 100%;
        }
        &:first-child {
          margin-right: 14px;
        }
        // &:last-child {
        //   //margin-left: 14px;
        //   width: 50%;
        // }
      }
    }
    .blood-grp {
      width: 100%;
    }
    .input-field {
      width: 100%;
      margin: 15px 0 !important;
    }

    .dob {
      width: 100%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border: 1px solid #ccc;
      div {
        &:before {
          border-bottom: none !important;
        }
      }
      label {
        padding-left: 10px;
        position: absolute;
        top: -9px;
        background: #fff;
        left: 4px;
      }
      div {
        padding-left: 15px;
      }
      button {
        outline: none !important;
      }
      input {
        border: none !important;
      }
    }
  }
  .save-btn {
    background: var(--tal_primary);
    color: #fff;
    margin-left: 10px;
    &:hover {
      background: var(--tal_primary);
    }
  }
}
