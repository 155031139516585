.bar_nav {
  position: sticky;
  top: 78px;
}
.line_align {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .para_line {
    font-weight: bold;
  }
}
.point_details {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  &:hover {
    background: #eee;
  }
}
