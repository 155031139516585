@import "../../sass/variables";
@import "../../sass/mixins";

.profile-drawer-container {
  .MuiDrawer-paperAnchorDockedLeft {
    max-width: 320px !important;
    z-index: 99999;
    @media screen and (max-width: 960px) {
      min-width: 100% !important;
    }
  }
}

.custom-error {
  font: 500 12px $font-regular !important;
  color: red;
}

@media screen and (max-width: 600px) {
  .profile-left {
    .hamburger-icon {
      display: none !important;
    }
  }
  .profile-right {
    .center,
    .right {
      max-width: 100% !important;
      flex-basis: 100% !important;
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
}

.seperator-1 {
  display: none;
}
