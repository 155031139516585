.password-reset-container {
 
  padding: 0 50px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  img {
    margin: 0 auto;
  }
  .t-s {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    p {
      line-height: 0;
      color: blue;
      span {
        color: var(--tal_primary);
        font-size: 12px;
      }
    }
  }
  p {
    margin: 0;
  }
  p:nth-child(2) {
    font-size: 24px;
    font-weight: 500;
    color: var(--tal_primary);
  }

  p:nth-child(4) {
    font-size: 14px;
  }

  .otp-boxes {
    input {
      width: 40px !important;
      height: 40px;
    }
  }

  .reset-password {
    margin: 0 auto;
    text-align: center;
    min-height: 73vh;
    @media screen and (max-width: 600px) {
      min-height: 64vh;
    }
  }
}
