@import "../../sass/mixins";
@import "../../sass/variables";

$green: #22b573;
$grey: #555;
$transitionSpeed: 200ms;

.headmenu {
  border-bottom: 1px solid #f1f1f1;
  padding: 0 !important;
  margin: 0 !important;
  background-color: rgb(231, 231, 231) !important;

  .border-left {
    border-left: 3px solid rgb(205, 38, 39) !important;
    padding: 0;
    margin-left: 30px;
    border-bottom: 1px solid #f6f6f6;
  }

  .mainmenu {
    width: 100%;
    padding: 0 10px 0 0;
    border-bottom: 1px solid #e9e9e9;
    background: white;

    .submenu {
      background-color: white !important;
      padding: 10px 0;
    }
  }
}

@mixin transition($time, $type, $property) {
  transition: $time $type $property;
  -moz-transition: $time $type $property;
  -webkit-transition: $time $type $property;
}

// Create mixin for transition property with transition dela
@mixin transition-with-delay($tim, $dela, $typ, $propert) {
  transition: $tim $dela $typ $propert;
  -moz-transition: $tim $dela $typ $propert;
  -webkit-transition: $tim $dela $typ $propert;
}
.title-length {
  font-size: 18px;
  margin-left: 5px;
  position: absolute;
  top: 21px;
  color: #ccc;
  right: 1px;
  padding: 9px;
  background: #fff;
}

.career-help-container {
  .container-center {
    background: #fff;
    min-height: 500px;
    text-align: left;
    @media screen and (max-width: 600px) {
      padding: 20px 10px;
    }
    .header {
      padding: 15px 10px;
      background: linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%);
      border-radius: 4px;
      @media screen and (max-width: 600px) {
        text-align: center;
      }
    }
  }

  .form-container {
    margin: 20px;

    @media screen and (max-width: 600px) {
      margin: 20px 0;
    }

    .mentor-mentee-container {
      .mentor-mentee {
        padding: 10px;
        background: #eff1f9;
        border-radius: 4px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
      }
      .mentor-mentee-selected {
        padding: 20px 60px;
        background: #f17121;
        color: #fff;
        border-radius: 4px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        height: unset;
        justify-content: space-evenly;
      }
      .mentor-mentee-selected p {
        color: #fff;
      }
      .mentor-mentee-selected img {
        width: 22px;
        height: 24px;
      }
      .mentor-mentee-selected svg {
        margin-left: 15px;
        width: 31px;
        height: 37px;
        margin-top: -5px;
      }
      .mentor-mentee-selected-last {
        background: #fcae17;
      }

      .crtitle {
        text-align: center;
        color: #000;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      .careertitle {
        color: #507285;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 25px;
      }
    }
    .userprofileFormBlk {
      display: inline-block;
      width: 100%;
      margin: 10px 0px;
      .PhoneInput {
        border-radius: 3px;
        height: 40px;

        margin: 0px;
        background: #fff;
        border: 1px solid #ccc;

        // padding: 30px 6px;
        input {
          background: transparent;
        }
        .PhoneInputInput {
          border: none !important;
          height: 40px;
          font-size: 1em;
          border: none;
          &:focus {
            outline: none;
          }
        }
      }
      .secFormLabel {
        color: #757575;
        display: inline-block;
        font-family: "Roboto";
        font-weight: 500;
        margin-bottom: 15px;
        font-size: 14px;
      }
      .secInlineHeader {
        color: var(--tal_primary);
        text-transform: uppercase;
        font-weight: 900;
        margin: 10px 0px 0px;
      }
      .saveBtn {
        background-color: var(--tal_primary);
        font-weight: 500;
        font-family: "Roboto";
        margin-right: 10px;
        width: 100px;
      }
      .cancelBtn {
        background-color: #757575;
        font-weight: 500;
        font-family: "Roboto";
        width: 100px;
      }
      .secMainDivide {
        float: left;
        width: 100%;
        margin: 20px 0px;
        height: 2px;
        background-color: #9e9e9e;
      }
      .profileInterests {
        border: 1px solid #ccc;
        padding: 30px 25px;
        li {
          display: inline-block;
          margin: 0px 10px 10px 0px;
          .MuiChip-root {
            //this is for the selected interest chip
            border-radius: 2px;
            background: #f44336;
            color: #fff;
            border: 1px solid #f44336;
          }
          .MuiChip-deletable {
            border-radius: 2px;
            background: #eff5fe;
            color: #3081f7;
            border: 1px solid #c1dafc;
            svg {
              color: #3081f7;
            }
          }
        }
      }
      .noEdit {
        .secFormLabel {
          color: #bdbdbd;
          margin-top: 5px;
        }
        input {
          border: 1px solid #e0e0e0;
        }
        .saveBtn {
          background-color: var(--tal_primary);
          font-weight: 500;
          font-family: "Roboto";
          margin-right: 10px;
          width: 100px;
        }
        .cancelBtn {
          background-color: #bdbdbd;
        }
      }
      .volunteerDesc {
        width: 100% !important;
        height: 105px !important;
        border: 1px solid #ccc;
        border-radius: 3px;
        background: transparent;
      }
    }
    .text-field-box {
      margin: 10px 0px;
      position: relative;

      .text-field {
        width: 100%;
        margin: 5px 0;
      }
      
    }
    .address-field-box {
      margin: 10px 0px;
    }
    .checkbox-box {
      margin: 10px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: top;
      a {
        color: #f44336;
      }
    }
  }
  
}

.careerCheck {
  display: flex !important;
  align-items: center;
  margin: 10px 0 0 15px;
  cursor: pointer;
  .checkTxt span {
    font-size: 0.875rem;
  }
}
.career-width{
  width: 70%;
  margin: 0 auto;
@media screen and (max-width:1024px){
  width: 100%; 
}
}
@media screen and (max-width:768px){
.career100{width: 100%;display:unset;}
}
.width60{
  width: 70%;
  margin: 0 auto;
  @media screen and (max-width:1024px){
    width: 100%;
  }
}

.tooltip-css {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  > span {
    flex: 95%;
  }
  > div {
    z-index: 1 !important;
  }
  
  .tooltip-text {
    height: 20px;
  }
  .tooltip-text + div > div {
    font-size: 14px;
  }
  svg {
    opacity: 0.5;
    cursor: pointer;
    width: 26px;
    height: 26px;
  }
  .ck-rounded-corners {
    width: 100% !important;
  }
  .tool-btn-img{         
    width:48%;
  }
  @media screen and (max-width:600px){
  .tool-btn-img{         
    width: 100%;
  }
  .tool-btn-img button{
    width: 95% !important;
  }
}
}
.cov-input textarea, .cov-input input{
  padding-right: 90px;
  @media screen and (max-width:480px) {
    padding-right: 50px;
    
  }
}
.tooltip-css div[role="tooltip"] {
  z-index: 2 !important;
  display: block;
}
