$color-red: #ff0000;
$color-green: #00ff00;
$color-blue: #0000ff;
$color-white: #fff;
$color-black: #000;
$color-light-grey: #e5e5e5;
$color-meta-text: #bcbcbc;
$primary-button:var(--tal_primary) ;
// $primary-button: #2E2E2E;
$font-playfair: "Playfair Display";
$font-roboto: "Roboto";
$font-regular: "Roboto";
$logo-background: #f35a57 ;

$linear-gradient1: rgba(56, 239, 125, 0.7);
$linear-gradient2: rgba(17, 153, 142, 0.9);

$margin-top: 60px;

$font-weights: (
  "regular": 400,
  "medium": 500,
  "bold": 800,
);
